import React, { useState, useRef, useEffect } from "react";
import "./dist/camera.css";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";

// import faceHolderImage from "../../assets/faceholder.webp";

export const Camera = () => {
  const [videoConstraints] = useState({
    width: 1280,
    height: 720,
    facingMode: "user",
  });

  const webcamRef = useRef(null);

  const [scan, setScan] = useState(false);
  const navigate = useNavigate();

  //i want a countdown of 3 seconds
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    if (scan) {
      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 0) {
            clearInterval(interval);
            navigate("/beauty");
            return prev;
          } else {
            return prev - 1;
          }
        });
      }, 1300);
      return () => clearInterval(interval);
    }
  }, [scan]);
  return (
    <>
      <div
        className={
          (videoConstraints.facingMode === "user" ? "video-flip" : "") +
          " webcam-container "
        }
      >
        {scan && (
          <>
            <div className="scanning"></div>
          </>
        )}
        <div className={" faceholder"}>
          {/* <img src={faceHolderImage} alt="face holder image" /> */}
          {scan && (
            <>
              <div className="countdown-faceholder">
                <div className="text-white poppins-bold f-26 text-center">
                  {countdown}
                </div>
              </div>
            </>
          )}
          <div className="faceholder-spinner">
            {scan && (
              <>
                <RotatingLines
                  visible={true}
                  height="100"
                  width="100"
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.5s"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </>
            )}
          </div>
          <svg
            width="570"
            height="733"
            viewBox="0 0 570 733"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="faceholder-svg"
          >
            <path
              d="M3.68268 337.518C-4.09029 264.433 7.50082 213.87 7.50082 213.87C7.50082 213.87 42.51 1.69373 283.054 1.69373C523.598 1.69373 558.607 213.87 558.607 213.87C558.607 213.87 574.435 270.388 565.275 337.518C556.114 404.648 483.959 732.152 283.054 732C82.8645 731.806 11.4556 410.603 3.68268 337.518Z"
              stroke="white"
              strokeWidth="2"
            />
          </svg>
        </div>
        <Webcam
          audio={false}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          ref={webcamRef}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        {scan ? (
          <>
            <div className="bottom-text-camera w-100 d-flex align-items-center justify-content-center pb-5 ">
              <div className="pb-0 pb-md-5 pb-lg-4">
                <div className="f-32 py-4 px-5 bg-white br-49 text-orange poppins-semibold text-center ">
                  Are you ready to reveal <br /> your skin’s age ?
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="bottom-text-camera w-100 pb-0 pb-md-5 pb-lg-3">
              <div className="f-32 pb-3 pb-md-4 pb-lg-3 text-white poppins-semibold text-center px-4">
                Center your face in front of the camera <br /> and smile 😊
                because you deserve it!
              </div>
              <div
                className="next-btn m-auto text-white poppins-medium f-26 text-center py-3"
                onClick={() => setScan(true)}
              >
                Scan
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
