import React from "react";
import { Link } from "react-router-dom";
import "./dist/welcome.css";
import womenImage from "../../assets/women_background.webp";
import gradientImage from "../../assets/beige_gradient.webp";
import logoImage from "../../assets/avene_logo.png";

export const Welcome = () => {
  return (
    <>
      <div className="bg-beige position-relative">
        <div className="bg-women">
          <img src={womenImage} alt="women avene" />
        </div>
        <div className="beige-background-image">
          <img src={gradientImage} alt="beige gradient" />
        </div>
        <div className="container center ">
          <div className="row h-100" data-aos="fade-up" data-aos-offset="300">
            <div className="col-12">
              <div className="logo text-center  pt-4">
                <img
                  src={logoImage}
                  style={{ maxWidth: "215px" }}
                  alt="logo avene"
                />
              </div>
            </div>

            <div className="col-12 align-self-end pb-5">
              <div className="f-38 text-darkgrey text-center pb-5 poppins-bold">
                Do you want to know
                <br />
                your real SKIN age?
              </div>
              <div className="pb-5">
                <Link to="/signup" style={{ textDecoration: "none" }}>
                  <div className="sign-in text-center cursor-pointe text-orange poppins-medium d-flex justify-content-center align-items-center">
                    <div className="">Sign In</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
