import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./dist/signup.css";
import logoImage from "../../assets/avene_logo_white.webp";
import whiteArrow from "../../assets/Down_Arrow.webp";
import countries from "./countries.json";
export const Signup = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState("");
  const [occupation, setOccupation] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    try {
      const formData = {
        firstName,
        lastName,
        phone,
        country,
        status,
        occupation,
        email,
        source_url: "https://game.aveneme.com/",
      };

      console.log("submitted this data : ", formData);
      let x = await axios.post(
        "https://avene.sheefra.company/public/api/avene-forms",
        formData
      );
      console.log("response from server : ", x.data);

      navigate("/open-camera");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="bg-orange position-relative">
        <div className="container center ">
          <div className="row px-md-5" data-aos="fade-up" data-aos-offset="300">
            <div className="col-12 pt-5">
              <div className="logo text-center pt-4 pb-4">
                <img
                  style={{ maxWidth: "280px" }}
                  src={logoImage}
                  alt="logo avene"
                />
              </div>
            </div>
            <div className="col-12 pt-lg-4 pt-md-5 pt-4">
              <div className="poppins-semibold f-28 text-white text-center pt-4">
                Fill in your information to start the game
              </div>
            </div>

            <div className="col-6 pt-lg-5 pt-sm-4 pt-2">
              <div className="input">
                <input
                  className="px-4"
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-6 pt-lg-5 pt-sm-4 pt-2">
              <div className="input">
                <input
                  className="px-4"
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-6 pt-lg-4 pt-md-5 pt-4">
              <div className="input">
                <input
                  className="px-4"
                  type="number"
                  placeholder="Phone Number"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-6 pt-lg-4 pt-md-5 pt-4">
              <div className="input">
                <select
                  name=""
                  id=""
                  className="px-4"
                  style={{ backgroundImage: `url(${whiteArrow})` }}
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                >
                  <option value="">Country</option>

                  {countries.countries.map((country) => {
                    return (
                      <option key={country.name} value={country.name}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-6 pt-lg-4 pt-md-5 pt-4">
              <div className="input">
                <select
                  name=""
                  id=""
                  className="px-4"
                  style={{ backgroundImage: `url(${whiteArrow})` }}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="">Status</option>
                  <option value="Married">Married</option>
                  <option value="Single">Single</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="col-6 pt-lg-4 pt-md-5 pt-4">
              <div className="input">
                <select
                  name=""
                  id=""
                  className="px-4"
                  style={{ backgroundImage: `url(${whiteArrow})` }}
                  onChange={(e) => {
                    setOccupation(e.target.value);
                  }}
                >
                  <option value="">Occupation</option>
                  <option value="Beauty">Beauty</option>
                  <option value="Tech Industry">Tech Industry</option>
                  <option value="Employee">Employee</option>
                  <option value="Entrepreneur">Entrepreneur</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="col-12 pt-lg-4 pt-md-5 pt-4">
              <div className="input">
                <input
                  className="px-4"
                  type="email"
                  placeholder="Email Address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="col-12 pt-lg-4 pt-md-5 pt-4">
              <div
                className="sign-in text-center py-3 cursor-pointe text-orange d-flex justify-content-center align-items-center"
                onClick={() => {
                  handleSubmit();
                }}
              >
                <div className="">Submit</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
