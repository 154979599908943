import React, { useState, useRef } from "react";
import "./dist/cameraCream.css";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";

import bottleImage from "../../assets/creme_bottle.webp";
import { StartCream } from "../../components/cameraCreamFlow/startCream";

export const CameraCream = () => {
  const [videoConstraints, setVideoConstraints] = useState({
    width: 1280,
    height: 720,
    facingMode: "user",
  });

  const webcamRef = useRef(null);

  const [scan, setScan] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div
        className={
          (videoConstraints.facingMode === "user" ? "video-flip" : "") +
          " webcam-container "
        }
      >
        <Webcam
          audio={false}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          ref={webcamRef}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        {scan ? (
          <></>
        ) : (
          <>
            {" "}
            <div className="bottle-image-container-cream">
              <img src={bottleImage} alt="creme bottle" />
            </div>
            <div className="bottom-text w-100">
              <div className="f-32 pb-3 pb-md-5 text-white poppins-semibold  px-4">
                Grab the Hyaluron serum
                <br />
                and follow the instructions
              </div>
            </div>
          </>
        )}
      </div>

      <StartCream setScan={setScan} webcamRef={webcamRef} />
    </>
  );
};
