import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./dist/App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Welcome } from "./pages/welcome";
import { Signup } from "./pages/signup";
import { OpenCamera } from "./pages/openCamera";
import { Beauty } from "./pages/beauty";
import { Camera } from "./pages/camera";
import { CameraCream } from "./pages/cameraCream";
import ImageContext from "./context/imageContext";
import { Final } from "./pages/final";

function App() {
  const [finalImage, setFinalImage] = useState("");
  useEffect(() => {
    // Initialize Aos and check if the user is on the landing page
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <ImageContext.Provider
      value={{
        finalImage: finalImage,
        setFinalImage: setFinalImage,
      }}
    >
      <Router>
        <Routes>
          <Route exact path="/" element={<Welcome />}></Route>
          <Route exact path="/signup" element={<Signup />}></Route>
          <Route exact path="/open-camera" element={<OpenCamera />}></Route>
          <Route exact path="/beauty" element={<Beauty />}></Route>
          <Route exact path="/camera" element={<Camera />}></Route>
          <Route exact path="/camera-cream" element={<CameraCream />}></Route>
          <Route exact path="/final" element={<Final />}></Route>
          <Route path="*" element={"404"}></Route>
        </Routes>
      </Router>
    </ImageContext.Provider>
  );
}

export default App;
