import React from "react";
import { Link } from "react-router-dom";
import "./dist/openCamera.css";

import cameraImage from "../../assets/camera.webp";
import oldWoman from "../../assets/old_woman.webp";
import squareImage from "../../assets/openCameraSquare.png";

export const OpenCamera = () => {
  return (
    <>
      <div className="bg-orange position-relative">
        <div className="old-woman-bg">
          <img src={oldWoman} alt="old woman" />
          <div className="overlay-dark"></div>
        </div>
        <div className="container center ">
          <div className="row h-100" data-aos="fade-up" data-aos-offset="300">
            <div className="col-12 pt-4 align-self-end"></div>

            <div className="col-12 pt-5 align-self-end">
              <div className="poppins-semibold f-32 text-white text-center py-4">
                Open the Camera and smile,
                <br />
                Because you deserve it!
              </div>
              <div className="logo text-center pt-4 pb-4">
                <img
                  style={{ maxWidth: "280px" }}
                  src={squareImage}
                  alt="logo avene"
                />
              </div>
            </div>

            <div className="col-12 pt-5 mt-3 mt-md-0 mt-5 pb-5">
              <Link to="/camera" style={{ textDecoration: "none" }}>
                <div className="sign-in text-center py-3 cursor-pointe text-orange d-flex align-items-center justify-content-center">
                  <div className="">Open Camera</div>
                  <img
                    style={{ width: "50px" }}
                    className="pl-3"
                    src={cameraImage}
                    alt="camera icon"
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
