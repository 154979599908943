import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import dropLocationImage from "../../assets/placement.webp";
import rightHandImage from "../../assets/hand.webp";
import creamDropperImage from "../../assets/dropper.webp";
import ImageContext from "../../context/imageContext";

export const StartCream = (props) => {
  const { webcamRef, setScan } = props;
  const [currentState, setCurrentState] = useState("start");
  const [stepsDone, setStepsDone] = useState([]);
  const { finalImage, setFinalImage } = useContext(ImageContext);
  // const states = ["start", "refill", "right", "left", "down", "scan", "done"];
  const navigate = useNavigate();

  //flips the image
  const handleFlip = (tempImage) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Set crossOrigin attribute to handle CORS
      img.src = tempImage;

      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        // Flip the image
        ctx.translate(canvas.width, 0);
        ctx.scale(-1, 1);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Get the base64-encoded image data
        const dataUrl = canvas.toDataURL("image/png");

        resolve(dataUrl);
      };

      img.onerror = function (error) {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (stepsDone.length >= 3) {
      if (
        stepsDone.includes("right") &&
        stepsDone.includes("left") &&
        stepsDone.includes("bottom")
      ) {
        setTimeout(() => {
          setCurrentState("scan");
          setScan(true);
        }, 1200);

        setTimeout(async () => {
          setCurrentState("done");
          setFinalImage(await handleFlip(webcamRef.current.getScreenshot()));
          navigate("/final");
        }, 4200);
      }
    }
  }, [stepsDone]);

  useEffect(() => {
    console.log("final image in start cream:", finalImage);
  }, [finalImage]);
  return (
    <>
      <div className={"start-cream"}>
        {currentState === "scan" ? (
          <></>
        ) : (
          <>
            <div className="placements-container position-relative">
              {/* right placement */}
              <div
                className={
                  (stepsDone.includes("right") &&
                    " right liquiddrop animate ") + " right-placement"
                }
                onClick={() => {
                  setCurrentState("right");
                  setStepsDone([...stepsDone, "right"]);
                }}
              >
                <img
                  className="pointer placement"
                  src={dropLocationImage}
                  // style={{ maxWidth: "100px" }}
                  alt="right placement"
                />
              </div>
              {/* left placement */}
              <div
                className={
                  (stepsDone.includes("left") && " left liquiddrop animate ") +
                  " left-placement"
                }
              >
                <img
                  className="pointer placement"
                  src={dropLocationImage}
                  alt="left placement"
                  onClick={() => {
                    setCurrentState("left");
                    setStepsDone([...stepsDone, "left"]);
                  }}
                />
              </div>
              {/* bottom placement */}
              <div
                className={
                  (stepsDone.includes("bottom") &&
                    " bottom liquiddrop animate ") + " bottom-placement"
                }
                style={stepsDone.includes("bottom") ? { left: "43%" } : {}}
              >
                <img
                  className="pointer placement"
                  src={dropLocationImage}
                  alt="bottom placement"
                  onClick={() => {
                    setCurrentState("bottom");
                    setStepsDone([...stepsDone, "bottom"]);
                  }}
                />
              </div>

              {/* creme dropper inside*/}
              <div
                className={
                  (currentState === "refill" && " refill ") +
                  (currentState === "right" && " right ") +
                  (currentState === "left" && " left ") +
                  (currentState === "bottom" && " bottom ") +
                  " creme-dropper-inside"
                }
              >
                <img
                  className="pointer"
                  src={creamDropperImage}
                  alt="right creme dropper"
                  onClick={() => {
                    setCurrentState("refill");
                    setStepsDone([...stepsDone, "refill"]);
                  }}
                />
              </div>
            </div>
          </>
        )}
   

        {/* right popup text */}
        <div
          className={
            (currentState === "right" && " right ") + " right-popup-text"
          }
        >
          <div className="text-orange poppins-semibold f-36 text-center">
            6%
          </div>
          <div className="text-white poppins-semibold f-32 text-center">
            Niacinamide
          </div>
        </div>
        {/* left popup text */}
        <div
          className={(currentState === "left" && " left ") + " left-popup-text"}
        >
          <div className="text-orange poppins-semibold f-36 text-center">
            1.5%
          </div>
          <div className="text-white poppins-semibold f-32 text-center">
            Hyaluronic acid
          </div>
        </div>
        {/* bottom popup text */}
        <div
          className={
            (currentState === "bottom" && " bottom ") + " bottom-popup-text"
          }
        >
          <div className="text-white poppins-semibold f-32 text-center">
            Avène thermal
            <br />
            spring water
          </div>
        </div>

        {/* right hand */}
        {currentState === "scan" ? (
          <></>
        ) : (
          <>
            {" "}
            <div
              className={
                (currentState === "refill" && " refill ") +
                (currentState === "right" && " right ") +
                (currentState === "left" && " left ") +
                (currentState === "bottom" && " bottom ") +
                " right-hand-reset"
              }
            >
              <img
                className="pointer"
                src={rightHandImage}
                alt="right hand"
                onClick={() => {
                  setCurrentState("refill");
                  setStepsDone([...stepsDone, "refill"]);
                }}
              />
            </div>
          </>
        )}

        {/* creme dropper outside */}
        <div
          className={
            (currentState === "refill" && " refill ") +
            (currentState === "right" && " right ") +
            (currentState === "left" && " left ") +
            (currentState === "bottom" && " bottom ") +
            " creme-dropper-outside"
          }
        >
          <img
            className="pointer"
            src={creamDropperImage}
            alt="right creme dropper"
            onClick={() => {
              setCurrentState("refill");
              setStepsDone([...stepsDone, "refill"]);
            }}
          />
        </div>

        {/* scanner */}
        {currentState === "scan" && (
          <>
            <div className="scanning"></div>
            <div className="smile poppins-bold">
              Look to the camera and SMILE!
            </div>
          </>
        )}
      </div>
    </>
  );
};
