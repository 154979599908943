import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./dist/final.css";
import "./dist/filters.css";
import stars from "../../assets/stars.webp";
import renewImage from "../../assets/renew_skin.png";
import logoImage from "../../assets/logo_orange.webp";
import bottleImage from "../../assets/creme_bottle.webp";
import ImageContext from "../../context/imageContext";

export const Final = () => {
  const navigate = useNavigate();

  const { finalImage } = useContext(ImageContext);

  useEffect(() => {
    function createStars() {
      console.log("createStars");
      const body = document.getElementById("starshine");
      const template = document.querySelector(".template.shine");
      const stars = 80;
      const sparkle = 20;

      let size = "small";

      const createStar = () => {
        const star = template.cloneNode(true);
        star.removeAttribute("id");
        star.style.top = `${Math.random() * 100}%`;
        star.style.left = `${Math.random() * 100}%`;
        star.style.animationDelay = `${Math.random() * sparkle}s`;
        star.classList.add(size);
        body.appendChild(star);
      };

      for (let i = 0; i < stars; i++) {
        if (i % 2 === 0) {
          size = "small";
        } else if (i % 3 === 0) {
          size = "medium";
        } else {
          size = "large";
        }

        createStar();
      }
    }

    // Call the function
    createStars();
  }, []);

  //after 15 seconds, navigate to the welcome page
  useEffect(() => {
    const interval = setInterval(() => {
      navigate("/");
    }, 15000);
    return () => clearInterval(interval);
  }, [navigate]);
  return (
    <>
      <div
        className="bg-beige position-relative"
        // style={{
        //   backgroundImage: `url(${finalImage})`,
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        //   backgroundPosition: "center",
        // }}
      >
        <div className="final-image-bg">
          <img
            src={finalImage}
            className="filter-brightness"
            alt="final image"
          />
        </div>
        <div className="overlay-filter"></div>

        <div className="left-renew-image">
          <img src={renewImage} alt="renews skin" />
        </div>

        <div className="right-bottle-image pr-2 pr-md-5 pr-lg-3">
          <img src={bottleImage} alt="creme bottle" />
        </div>

        <div className="bottom-text-final w-100 text-center pt-5">
          <div className="f-32 poppins-semibold text-white pb-5">
            Take care of yourself and your skin !
            <br />
            Keep shining wherever you go
          </div>
          <div className="pb-md-5 pb-lg-3"></div>
          <div className="pb-md-5 pb-lg-3"></div>
        </div>

        <div className="container center">
          <div className="row h-100" data-aos="fade-up" data-aos-offset="300">
            <div className="col-12">
              <div className="logo text-center pt-4">
                <img src={logoImage} alt="logo avene" />
              </div>
            </div>

            <div className="col-12 pt-5">
              <div id="starshine">
                <div
                  className="template shine"
                  style={{ backgroundImage: `url(${stars})` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
