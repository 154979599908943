import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./dist/beauty.css";
import stars from "../../assets/stars.webp";
import womenLeftImage from "../../assets/women_left.webp";
import logoImage from "../../assets/logo_orange.webp";
import bottleImage from "../../assets/creme_bottle.webp";

export const Beauty = () => {
  const navigate = useNavigate();

  useEffect(() => {
    function createStars() {
      console.log("createStars");
      const body = document.getElementById("starshine");
      const template = document.querySelector(".template.shine");
      const stars = 50;
      const sparkle = 20;

      let size = "small";
      console.log("before createstar const");

      const createStar = () => {
        console.log("inside");

        const star = template.cloneNode(true);
        star.removeAttribute("id");
        star.style.top = `${Math.random() * 100}%`;
        star.style.left = `${Math.random() * 100}%`;
        star.style.animationDelay = `${Math.random() * sparkle}s`;
        star.classList.add(size);
        body.appendChild(star);
      };

      for (let i = 0; i < stars; i++) {
        if (i % 2 === 0) {
          size = "small";
        } else if (i % 3 === 0) {
          size = "medium";
        } else {
          size = "large";
        }
        console.log("inside");

        createStar();
      }
    }

    // Call the function
    createStars();
  }, []);
  return (
    <>
      <div className="bg-beige position-relative center pb-0 pb-md-5 pb-lg-0">
        <div className="beige-background-image">
          <img src={womenLeftImage} alt="women" />
        </div>
        <div className="container h-100">
          <div className="row h-100" data-aos="fade-up" data-aos-offset="300">
            <div className="col-12">
              <div className="logo text-center py-4">
                <img src={logoImage} alt="logo avene" />
              </div>
            </div>

            <div className="col-12 pt-5">
              <div
                className="bg-orange br-49 pt-2 m-auto"
                style={{ height: "300px", maxWidth: "500px" }}
              >
                <div className="button-stars">
                  <div className="btn-container">
                    <div id="starshine">
                      <div
                        className="template shine"
                        style={{ backgroundImage: `url(${stars})` }}
                      ></div>
                    </div>
                    <Link to="#" style={{ textDecoration: "none" }}>
                      <div className="sign-in text-center text-darkgrey d-flex align-items-center justify-content-center">
                        <div className="">
                          <span className="poppins-bold"> HYALURON</span>
                          <br />
                          <span className="poppins-medium">ACTIV B3</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="pt-5"></div>
                <div className="text-white poppins-semibold f-36 text-center pt-5">
                  AGE is just a number!
                  <br />
                  You are beautiful the
                  <br />
                  way you are!
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="bottle-image-container-beauty">
                <img src={bottleImage} alt="creme bottle" />
              </div>

              <div className="f-32 poppins-semibold text-darkgrey pb-4">
                Avène is dedicated to keep
                <br />
                your skin <span className="poppins-bold"> YOUNG</span> at any
                age!
              </div>
              <div className="poppins-light-italic f-22 text-darkgrey py-3">
                To discover how, tap next
              </div>
              <div
                className="next-btn text-white poppins-medium f-26 text-center py-3"
                onClick={() => {
                  navigate("/camera-cream");
                }}
              >
                Next
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
